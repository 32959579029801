import { createStore as reduxCreateStore } from "redux"
import { merge } from 'lodash';

const SITE_LOADED = 'SITE_LOADED'

export const siteLoaded = () => ({ type: SITE_LOADED })

const initialState = { isLoaded: false }

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SITE_LOADED:
    return merge({}, state, { isLoaded: true } );
  default:
    return state;
  }
}

export const createStore = () => reduxCreateStore(reducer, initialState)
