import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import autoBind from 'auto-bind';

import { Flipper, Flipped } from 'react-flip-toolkit'

import AboutButton from '../aboutButton/aboutButton'
import AboutModal from '../aboutModal/aboutModal'


import styles from "./about.module.scss"

class About extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)
    this.state = {
      hideModal: true,
      showButtonStart: false,
      showButtonComplete: true,
      showModalStart: false,
      showModalComplete: false,
    }
  }

  toggleModal() {
    const { hideModal } = this.state
    if (hideModal) {
      this.setState({ hideModal: false })
      document.body.classList.add('noScroll')
    } else {
      this.setState({ showModalComplete: false });
      setTimeout(() => {
        this.setState({ hideModal: true })
      }, 400)
      document.body.classList.remove('noScroll')
    }
  }

  onButtonStart() {
    this.setState({
      showButtonStart: true,
      showButtonComplete: false,
      showModalStart: false,
      showModalComplete: false,
    })
  }

  onButtonComplete() {
    this.setState({
      showButtonStart: false,
      showButtonComplete: true,
      showModalStart: false,
      showModalComplete: false,
    })
  }

  onModalStart() {
    this.setState({
      showButtonStart: false,
      showButtonComplete: false,
      showModalStart: true,
      showModalComplete: false,
    })
  }

  onModalComplete() {
    this.setState({
      showButtonStart: false,
      showButtonComplete: false,
      showModalStart: false,
      showModalComplete: true,
    })
  }

  render() {
    const { className } = this.props
    const { hideModal, showButtonStart, showButtonComplete, showModalComplete } = this.state
    const variant = this.props['*'] === '' ? 'home' : 'single';

    return (
      <div className={ classNames(styles.about, className) }>
        <Flipper flipKey={ hideModal }>
          { !hideModal &&
            <AboutButton
              className={ classNames(styles.aboutButton, styles.showButtonComplete, styles.single, { [styles.showModalComplete]: !hideModal && !showModalComplete }) }
              onClick={ this.toggleModal }
              text="X"
            />
          }
          { hideModal &&
            <Flipped
              flipId="modal"
              transformOrigin="bottom right"
              scale
              opacity
              onStart={ this.onButtonStart }
              onComplete={ this.onButtonComplete }
            >
              <AboutButton
                className={ classNames(styles.aboutButton, styles[variant], { [styles.hidden]: false, [styles.showButtonStart]: showButtonStart, [styles.showButtonComplete]: showButtonComplete }) }
                onClick={ this.toggleModal }
                variant={ variant }
              />
            </Flipped>
          }
          { !hideModal &&
            <Flipped
              flipId="modal"
              transformOrigin="bottom right"
              scale
              opacity
              onStart={ this.onModalStart }
              onComplete={ this.onModalComplete }
            >
              <AboutModal
                className={ classNames(styles.aboutModal, { [styles.showModalComplete] : showModalComplete }) }
              />
            </Flipped>
          }
        </Flipper>
      </div>
    );
  }
}

About.propTypes = {
  className: PropTypes.string,
}

About.defaultProps = {
  className: null,
}

export default About
