import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

import styles from "./layout.module.scss"

class Layout extends React.Component {
  render() {
    const { children, className, pageMetadata } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                author
                description
                image
                keywords
                siteUrl
                title
              }
            }
          }
        `}
        render={ data => {
          const { siteMetadata } = data.site
          const metadata = {
            title: pageMetadata.title ? `${ siteMetadata.title } |  ${ pageMetadata.title }` : `${siteMetadata.title} | ${siteMetadata.description}`,
            ogTitle: pageMetadata.title ? `${ siteMetadata.title } - ${siteMetadata.description} | ${ pageMetadata.title }` : `${siteMetadata.title} | ${siteMetadata.description}`,
            author: siteMetadata.author,
            description: pageMetadata.description ? pageMetadata.description : siteMetadata.description,
            image: pageMetadata.image ? `${siteMetadata.siteUrl}${pageMetadata.image}` : `${siteMetadata.siteUrl}${siteMetadata.image}`,
            keywords: siteMetadata.keywords,
            type: pageMetadata.type ? pageMetadata.type : 'website',
            url: pageMetadata.slug ? `${ siteMetadata.siteUrl }${ pageMetadata.slug }` : siteMetadata.siteUrl,
          }
          return (
            <>
              <Helmet
                title={ metadata.title }
                meta={ [
                  { name: 'description', content: metadata.description },
                  { name: 'keywords', content: metadata.keywords },
                  { property: 'og:title', content: metadata.ogTitle },
                  { property: 'og:description', content: metadata.description },
                  { property: 'og:image', content: metadata.image },
                  { property: 'og:type', content: metadata.type },
                  { property: 'og:url', content: metadata.url },
                  { property: 'twitter:card', content: 'summary_large_image' },
                  { property: 'twitter:creator', content: metadata.author },
                  { property: 'twitter:title', content: metadata.ogTitle },
                  { property: 'twitter:description', content: metadata.description },
                  { property: 'twitter:image', content: metadata.image },
                ] }
                link={ [
                  { rel: 'image_src', href: metadata.image }
                ] }
              >
                <html lang="en" />
              </Helmet>
              <div className={ classNames(styles.layout, className) }>
                { children }
              </div>
            </>
          )
        } }
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  pageMetadata: PropTypes.shape({
    description: PropTypes.description,
    image: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.title,
  })
}

Layout.defaultProps = {
  className: null,
  pageMetadata: {}
}

export default Layout
