/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import Layout from "./src/components/layout/layout"
import About from './src/components/about/about'
import { wrapWithProvider } from './wrap-with-provider'

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = wrapWithProvider;

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      <Layout {...props}>{element}</Layout>
      { !props.pageContext.hideAbout &&
        <About {...props}/>
      }
    </>
  )
}
