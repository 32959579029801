// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/sarah/Code/vs/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-poem-poem-js": () => import("/Users/sarah/Code/vs/src/templates/poem/poem.js" /* webpackChunkName: "component---src-templates-poem-poem-js" */),
  "component---src-templates-social-card-social-card-js": () => import("/Users/sarah/Code/vs/src/templates/socialCard/socialCard.js" /* webpackChunkName: "component---src-templates-social-card-social-card-js" */),
  "component---src-templates-title-card-title-card-js": () => import("/Users/sarah/Code/vs/src/templates/titleCard/titleCard.js" /* webpackChunkName: "component---src-templates-title-card-title-card-js" */),
  "component---src-pages-404-js": () => import("/Users/sarah/Code/vs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attribution-js": () => import("/Users/sarah/Code/vs/src/pages/attribution.js" /* webpackChunkName: "component---src-pages-attribution-js" */),
  "component---src-pages-index-js": () => import("/Users/sarah/Code/vs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/Users/sarah/Code/vs/.cache/data.json")

