import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from "./aboutButton.module.scss"

function AboutButton({ className, onClick, hidden, text, variant, ...rest }) {
  return (
    <button
      className={ classNames(styles.aboutButton, styles[variant], className) }
      onClick={ onClick }
      { ...rest }
    >
      <span>{ text }</span>
    </button>
  )
}

AboutButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['home', 'single']),
}

AboutButton.defaultProps = {
  className: null,
  text: '&',
  variant: 'single',
}

export default AboutButton
