import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FaMediumM, FaTwitter, FaPenNib, FaInstagram } from 'react-icons/fa';

import styles from "./aboutModal.module.scss"

function AboutModal({ className, ...rest }) {
  return (
    <section
      className={ classNames(styles.aboutModal, className) }
      { ...rest }
    >
      <article>
        <div>
          <h1 className="h1">I am</h1>
          <div className={ styles.content }>
            <p className="content">an adventurer, artist, writer—from Dublin, Ireland, now living in Brooklyn, New York.</p>
          </div>
          <div className={ styles.socialLinks }>
            <a href="https://medium.com/@versus.verses"><FaMediumM /></a>
            <a href="https://instagram.com/versus.verses"><FaInstagram /></a>
            <a href="https://twitter.com/versus__verses"><FaTwitter /></a>
            <a href="mailto:versusversespoetry@gmail.com"><FaPenNib /></a>
          </div>
        </div>
      </article>
    </section>
  )
}

AboutModal.propTypes = {
  className: PropTypes.string,
}

AboutModal.defaultProps = {
  className: null,
}

export default AboutModal
